import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetailsService } from 'src/services/details/details.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  id : any;
  lang: string;
  Carousel:any;
    constructor(private activatedRoute:ActivatedRoute,public _detailsService:DetailsService,public translateService:TranslateService){}
    ngOnInit(): void {
      this.lang= localStorage.getItem('lang') || 'en'
      this.activatedRoute.paramMap.subscribe((params:ParamMap)=> {
        this.id = params.get('id')!
        // console.log("this is paramsdid" +this.id)
        this.getCarouselByID()
       });
      }
getCarouselByID(){
    this._detailsService.getCarouselByID(this.id).subscribe(
        ( response:any) => { console.log(response);
         this.Carousel = response.data
        //  console.log(this.Carousel)
       }
       );
}
changeLang(lang:string){
  localStorage.removeItem('lang')
  localStorage.setItem('lang',lang)
  this.translateService.use(lang)
  window.location.reload();

}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactUSURL, domain } from 'src/environments/environment';
import { IContactUsFormRequest } from './ContactUsFormRequest';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http:HttpClient) { }
baseUrl=ContactUSURL
 sendForm(request: IContactUsFormRequest){
  return this.http.post(this.baseUrl,request)
 } 

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HomeService } from 'src/services/home/home.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
Events:any;
lang: string;

  constructor(private translateService: TranslateService,private _homeService: HomeService) { }

  ngOnInit() {
    this.lang= localStorage.getItem('lang') || 'en'
    this.getEvents();
  }
  getEvents(){
    this._homeService.getAllEvents().subscribe(
      (data: any) => {
        this.Events = data.data;
        console.log(this.Events);
      },
      (error: any) => {
        console.log('There is an error ', error);
      }
    );
  }
  changeLang(lang:string){
    localStorage.removeItem('lang')
    localStorage.setItem('lang',lang)
    this.translateService.use(lang)
    window.location.reload();
  
  }
}

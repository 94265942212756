<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Our Services"|translate}}</h2>

                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"Assessment"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="ptb-100" dir="auto">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let service of Services">
            <!-- <div class="col-lg-4 col-sm-6">
                <div class="service-card-one gray-bg">
                    <div class="post-image">
                        <a > <img height="60" width="60" src="{{ service.image }}" alt="image"></a>
                        <br><br>                 
                    </div>
                    <h3>
                        <a *ngIf="service.titleAr && lang=='ar' && service.titleAr"  [routerLink]="['/service-details', service.id]">{{service.titleAr}}</a>
                        <a *ngIf="service.title && lang=='en' && service.title"  [routerLink]="['/service-details', service.id]">{{service.title}}</a>
                    </h3>
                    <p *ngIf="service.descriptionAr && lang=='ar' && service.descriptionAr" >{{service.descriptionAr.slice(0,100)}}...</p>
                    <p *ngIf="service.description && lang=='en' && service.description">{{service.description.slice(0,100)}}..</p>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div  class="single-blog-post">
                <div   class="post-image">
                    <a [routerLink]="['/assessment-details', service.id]"> <img  src="{{ service.image }}" alt="image"></a>
                </div>
                <div class="post-content">
                    <h3 *ngIf="service.titleAr && lang=='ar' && service.titleAr" ><a [routerLink]="['/assessment-details', service.id]">{{ service.titleAr.slice(0,45) }}</a></h3>
                    <h3 *ngIf="service.title && lang=='en' && service.title" ><a [routerLink]="['/assessment-details', service.id]">{{ service.title.slice(0,45) }}</a></h3>
                    <p *ngIf="service.descriptionAr && lang=='ar' && service.descriptionAr">{{ service.descriptionAr.slice(0,50) }}...</p>
                    <p  *ngIf="service.description && lang=='en'&& service.description">{{ service.description.slice(0,50) }}...</p>
                    <a  [routerLink]="['/assessment-details', service.id]" class="read-more-btn">{{"Read More"|translate}} <i class="flaticon-right-arrow"></i></a>
                </div>
            </div></div>  
        </ng-container >

           
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Intervention"|translate}}</h2>

                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"Intervention"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Project area -->
<div class="works-area ptb-100">
    <div   class="container-fluid">
        <div   class="section-title">
            <h2>{{"All Programs"|translate}}</h2>
        </div>

        <div  class="row">
            <ng-container   *ngFor="let program of Programs">

            <div  class="col-lg-4 col-sm-6 col-xl-3">
                <div  dir="auto" class="work-card">
                    <img [src]="program.image" alt="image">

                    <div dir="auto" class="content">
                        <span>
                            <a *ngIf="program.titleAr && lang=='ar' && program.titleAr" >{{program.titleAr}}</a>
                            <a *ngIf="program.title && lang=='en' && program.title" >{{program.title}}</a>

                        </span>
                        <h3>
                            <a *ngIf="program.descriptionAr && lang=='ar'" [routerLink]="['/program-details', program.id]">{{program.descriptionAr.slice(0,50)}}...</a>
                            <a *ngIf="program.description && lang=='en'" [routerLink]="['/program-details', program.id]">{{program.description.slice(0,50)}}...</a>
                        </h3>

                        <a class="work-btn" [routerLink]="['/program-details', program.id]">{{"Read More"|translate}}</a>
                    </div>
                </div>
            </div>
</ng-container>
          
        </div>
    </div>
</div>
<!-- End project area -->

import { Component, OnInit } from '@angular/core';
import { AboutUsService } from 'src/services/aboutUs/about-us.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public _aboutUsService :AboutUsService,public translateService :TranslateService) { }
aboutUs :any [];
FirstaboutUs :any;
lang: string;
  ngOnInit(): void {
 this.lang= localStorage.getItem('lang') || 'en'
 this.getSCarousels()

  }
  getSCarousels(){
    this._aboutUsService.getAboutUs().subscribe(
      (data: any) => {
        this.aboutUs = data.data;
        this.FirstaboutUs=this.aboutUs[0]
        console.log(this.aboutUs);
        console.log(this.FirstaboutUs);
      },
      (error: any) => {
        console.log('There is an error ', error);
      }
    );
  }
  changeLang(lang:string){
    localStorage.removeItem('lang')
    localStorage.setItem('lang',lang)
    this.translateService.use(lang)
    window.location.reload();
  
  }
}

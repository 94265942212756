<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}} sticky-top   ">
    <div class="karlson-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand"  routerLink="/"><img width="150" src="assets/img/100.png" alt="logo"></a>
                <button class="navbar-toggler bg-primary rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon  "></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" dir="auto">
                    <ul class="navbar-nav">
                        <li class="nav-item ">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">{{"Home"|translate}}</a>
                        </li>
                        <li class="nav-item " >
                            <a routerLink="/assessments" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
                                {{ "Assessment" | translate }}
                            </a>
                            <!-- <div class="dropdown-menu text-center " aria-labelledby="navbarDropdown" [ngClass]="{ 'show': dropdownOpen }" >
                                <ng-container *ngFor="let category of Categories">
                                    <a *ngIf="category.nameAR && lang=='ar'" class="dropdown-item" [routerLink]="['/services', category.id]">{{category.nameAR}}</a>
                                    <a *ngIf="category.name && lang=='en'" class="dropdown-item" [routerLink]="['/services', category.id]">{{category.name}}</a>
                                </ng-container>
                            </div> -->
                        </li>

                        <li class="nav-item dropdown">
                            <a routerLink="/programs" routerLinkActive="active" class="nav-link">{{"Intervention"|translate}}</a>


                        </li>
                        <!-- <li class="nav-item ">
                            <a routerLink="/Events" routerLinkActive="active" class="nav-link">{{"Events"|translate}} </a>

                        </li> -->
                        <li class="nav-item ">
                            <a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">{{"About Us"|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">{{"Book an Appointment"|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <button (click)="changeLang('ar')"  class="nav-link text-primary p-0 mx-3" *ngIf="lang=='en'">ع</button>
                        </li>
                        <li class="nav-item" *ngIf="lang=='ar'">
                            <button (click)="changeLang('en')"  class="nav-link text-primary p-0 mx-3 ">En</button>
                        </li>
                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/services/home/home.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-services-style-three',
  templateUrl: './services-style-three.component.html',
  styleUrls: ['./services-style-three.component.scss']
})
export class ServicesStyleThreeComponent  implements OnInit {

    constructor(public _homeService :HomeService,public translateService :TranslateService,private activatedRoute:ActivatedRoute) { }
Services :any [];
lang: string;
categoryId:string;
ServicesForAcategory:any;
    ngOnInit(): void {
      this.lang= localStorage.getItem('lang') || 'en'
      // this.activatedRoute.paramMap.subscribe((params:ParamMap)=> {
      //   this.categoryId = params.get('id')!
      //   //console.log("this is paramsd id" +this.categoryId)
      //    this.getServiceByCategory()
      //  });
      this.getServices()
    }

    
    // getServiceByCategory(){
    //     this._homeService.getAllServicesWithCategories(this.categoryId).subscribe(
    //         ( response:any) => { console.log(response);
    //           this.Services = response.data
    //        }
    //        );
    //     }


    getServices(){
      this._homeService.getAllServices().subscribe(
        (data: any) => {
          this.Services = data.data;
          console.log(this.Services);
        },
        (error: any) => {
          console.log('There is an error ', error);
        }
      );
    }

    changeLang(lang:string){
      localStorage.removeItem('lang')
      localStorage.setItem('lang',lang)
      this.translateService.use(lang)
      window.location.reload();

    }
}

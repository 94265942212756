import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
 import { aboutUsURL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {
  aboutUsDomain=aboutUsURL;
  constructor(private http:HttpClient) { }
    getAboutUs(){
      return this.http.get(this.aboutUsDomain+'/All',{headers:{skip:"true"}})
    }


}

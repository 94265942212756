import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { domain } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
 Domain=domain;
  constructor(private http:HttpClient) { }
  getServiceByID(Id:any) {
    return this.http.get(`${this.Domain}service/${Id}`,{headers:{skip:"true"}});
  }
  getprogramByID(Id:any) {
    return this.http.get(`${this.Domain}program/${Id}`,{headers:{skip:"true"}});
  }
  getCarouselByID(Id:any) {
    return this.http.get(`${this.Domain}carousel/${Id}`,{headers:{skip:"true"}});
  }
}

<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Contact"|translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"Contact"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->




<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3>{{"Email Here"|translate}}</h3>
                    <p><a href="mailto:info&#64;arabtesting.com">info&#64;arabtesting.com</a></p>
                    <!-- <p><a href="mailto:arabtesting&#64;gmail.com">arabtesting&#64;gmail.com</a></p> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-phone-call"></i>
                    </div>
                    <h3>{{"Location Here"|translate}}</h3>
                    <p><a href="https://maps.app.goo.gl/xrXpcqy7EngdCg8M7" target="_blank">150 Nw 72nd Ave Tower 1 Ste 455 #12936 Miami, Florida 33126</a></p>
                </div>
            </div> -->

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-marker"></i>
                    </div>
                    <h3>{{"Call Here"|translate}}</h3>
                    <strong>Egypt</strong>
                    <br>
                    <a href="tel:+201101702221">+201101702221</a>
                    <br>
                    <strong>United Arab Emirates</strong>
                    <br>
                    <a href="tel:00971509574720">00971509574720</a>
                    <br>
                    <strong>United State</strong>
                    <br>
                    <a href="tel:+17862977910">+17862977910</a>
                </div>
            </div>
        </div>
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2 class="text-capitalize">{{"You can book an appointment from here and we will contact you as soon as possible"|translate}}</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-4 col-md-4">
                <div class="contact-image">
                    <div class="it-banner-image owl-carousel owl-theme">
                        <div class="animate-image">
                            <img src="assets/img/it-banner/animate-img.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactForm" (submit)="onSubmit($event)" >
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input formControlName="name" name="name" type="text" class="form-control" placeholder="Name" />
                                <ng-container *ngIf="getName.touched">
                                    <p *ngIf="getName.hasError('required')" class="text-danger">Required</p>
                                </ng-container>
                                </div>     
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input formControlName="email" name="email" type="email" class="form-control " placeholder="Email" />
                                    <ng-container *ngIf="getEmail.touched">
                                        <p *ngIf="getEmail.hasError('required')" class="text-danger">Required</p>
                                        <p *ngIf="getEmail.hasError('email')" class="text-danger">Invalid Email Format</p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control" placeholder="Phone">
                                    <ng-container *ngIf="getPhoneNumber.touched">
                                        <p *ngIf="getPhoneNumber.hasError('required')" class="text-danger">Required</p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="subject" name="subject" id="subject" class="form-control" placeholder="Subject">
                                    <ng-container *ngIf="getSubject.touched">
                                        <p *ngIf="getSubject.hasError('required')" class="text-danger">Required</p>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="body" name="body" cols="30" rows="5" class="form-control " placeholder="Write Your Message!"></textarea>
                                    <ng-container *ngIf="getBody.touched">
                                        <p *ngIf="getBody.hasError('required')" class="text-danger">Required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <!-- <P>{{contactForm.value|json}}</P> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message <span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';

import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';

import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { FeaturesComponent } from './components/pages/features/features.component';

import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';

import { ServicesStyleThreeComponent } from './components/pages/services-style-three/services-style-three.component';
import { ProjectsStyleTwoComponent } from './components/pages/projects-style-two/projects-style-two.component';

const routes: Routes = [
    {path: '', component: HomeDemoOneComponent},

    {path: 'about', component: AboutComponent},

    {path: 'assessments', component: ServicesStyleThreeComponent},
    {path: 'assessment-details/:id', component: ServiceDetailsComponent},

    {path: 'programs', component: ProjectsStyleTwoComponent},

    {path: 'program-details/:id', component: ProjectDetailsComponent},
    // {path: 'Events', component: BlogComponent},
    //{path: 'blog-2', component: BlogRightSidebarComponent},
    {path: 'blog-details/:id', component: BlogDetailsComponent},

    {path: 'faq', component: FaqComponent},

    {path: 'contact', component: ContactComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }

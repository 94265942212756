<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Assessment Details"|translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"Assessment Details"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div dir="auto" class="" *ngIf="service" >
                <div class="text-center">
                    <h3  *ngIf="service.titleAr && lang=='ar' && service.titleAr">{{service.titleAr}}</h3>
                    <h3  *ngIf="service.title && lang=='en' && service.title">{{service.title}}</h3>
                    <div  class=" text-center ">
                        <img [src]="service.image" alt="image" class=" wow animate__animated animate__fadeInUp">
                    </div>
                </div>
               <hr>
                <!-- <p   *ngIf="service.descriptionAr && lang=='ar' && service.descriptionAr">{{service.descriptionAr}}</p>
                <p   *ngIf="service.description && lang=='en' && service.description">{{service.description}}</p> -->

                <div class="container ">
                    
                    <div *ngIf="service.bodyAr && lang=='ar' && service.bodyAr" class="  shadow m-2 p-3 p-lg-5  "[innerHTML]="service.bodyAr" > </div>
                    <div *ngIf="service.body && lang=='en' && service.body" class="  shadow m-2 p-3 p-lg-5  "[innerHTML]="service.body" > </div>
               
                    <!-- <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p> -->
                </div>

               
          

       
        </div> 
     </div>

        
    </div>
</section>
<!-- End Services Details Area -->

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
 import { programURL } from 'src/environments/environment';
 import { serviceURL } from 'src/environments/environment';
 import { carouselURL } from 'src/environments/environment';
 import { activitesURL  } from 'src/environments/environment';
 import { CategoriesURL } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
ProgramDomain=programURL;
ServiceDomain=serviceURL;
CarouselDomain=carouselURL;
activitesDomain=activitesURL;
CategoriesDomain=CategoriesURL;

  constructor(private http:HttpClient) { }


  getAllprograms(){
    return this.http.get(this.ProgramDomain+'/All',{headers:{skip:"true"}})
  }
  getAllServices(){
    return this.http.get(this.ServiceDomain+'/All',{headers:{skip:"true"}})
  }
  getAllServicesWithCategories(Id:string){
    return this.http.get(this.ServiceDomain+"/AllInACategory?CategoryID="+Id,{headers:{skip:"true"}});

  }
  getAllCarousels(){
    return this.http.get(this.CarouselDomain+'/All',{headers:{skip:"true"}})
  }
  getAllEvents(){
    return this.http.get(this.activitesDomain,{headers:{skip:"true"}})
  }
  getAllCategories(){
    return this.http.get(this.CategoriesDomain,{headers:{skip:"true"}})
  }
}

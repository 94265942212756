import { Component, ElementRef, OnInit ,AfterViewInit, ViewChild} from '@angular/core';
import { HomeService } from 'src/services/home/home.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-home-demo-one',
    templateUrl: './home-demo-one.component.html',
    styleUrls: ['./home-demo-one.component.scss']
})
export class HomeDemoOneComponent implements OnInit  {

    constructor(public _homeService :HomeService,private elementRef: ElementRef, private translateService:TranslateService) { }
Programs :any [];
Services :any [];
Carousels :any [];
lang: string;
    ngOnInit(): void {
   this.lang= localStorage.getItem('lang') || 'en'
   this.getSCarousels()
   this.getServices()
   this.getPrograms()
    }
    getSCarousels(){
      this._homeService.getAllCarousels().subscribe(
        (data: any) => {
          this.Carousels = data.data;
          console.log(this.Carousels);
        },
        (error: any) => {
          console.log('There is an error ', error);
        }
      );
    }
    getPrograms(){
      this._homeService.getAllprograms().subscribe(
        (data: any) => {
          this.Programs = data.data;
          console.log(this.Programs);
        },
        (error: any) => {
          console.log('There is an error ', error);
        }
      );
    }
    getServices(){
      this._homeService.getAllServices().subscribe(
        (data: any) => {
          this.Services = data.data;
          console.log(this.Services);
        },
        (error: any) => {
          console.log('There is an error ', error);
        }
      );
    }
    changeLang(lang:string){
      localStorage.removeItem('lang')
      localStorage.setItem('lang',lang)
      this.translateService.use(lang)
      window.location.reload();
    
    }
}


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';
import { emailPK, emailServiceId, emailTemplateId } from 'src/environments/environment';
import { ContactUsService } from 'src/services/contact-us/contact-us.service';
import { IContactUsFormRequest } from 'src/services/contact-us/ContactUsFormRequest';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(private contactUsService:ContactUsService) { }

  ngOnInit() {
  }
  
  contactForm=new FormGroup({
    name:new FormControl('',[Validators.required]),
    email:new FormControl('',[Validators.required,Validators.email]),
    body:new FormControl('',[Validators.required]),
    subject:new FormControl('',[Validators.required]),
    phoneNumber:new FormControl('',[Validators.required])
  })

 get getName(){
  return this.contactForm.controls['name']
 }
 get getEmail(){
  return this.contactForm.controls['email']
 }
 get getBody(){
  return this.contactForm.controls['body']
 }
 get getSubject(){
  return this.contactForm.controls['subject']
 }
 get getPhoneNumber(){
  return this.contactForm.controls['phoneNumber']
 }
 

   onSubmit(e: Event) {
    if(this.contactForm.valid){
      //console.log(this.contactForm.value)
      this.contactUsService.sendForm(this.contactForm.value as IContactUsFormRequest).subscribe({
        next:(res:any)=>{
          Swal.fire("Reservation Sent");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          
        },
        error:(err:any)=>{

        }
      })
    }
  // e.preventDefault();

  // emailjs
  //   .sendForm(emailServiceId, emailTemplateId, e.target as HTMLFormElement, {
  //     publicKey: emailPK,
  //   })
  //   .then(
  //     () => {
  //       Swal.fire("Message Sent!");
  //       window.location.reload();
  //       //console.log('SUCCESS!');
  //     },
  //     (error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",   
  //       });
  //       //console.log('FAILED...', (error as EmailJSResponseStatus).text);
  //     },
  //   );
  }
}

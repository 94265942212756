<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Projects Details"|translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"Projects Details"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Project Details Area -->
<section class="project-details-area ptb-100">
    <div class="container">
        <div class="row" *ngIf="program">
  
            <div  class="col-lg-12 col-md-12">
                <div dir="auto" class="projects-details-desc"  class="text-center">
                    <h3 *ngIf="program.titleAr && lang=='ar' && program.titleAr">{{program.titleAr}}</h3>
                    <h3 *ngIf="program.title && lang=='en' && program.title">{{program.title}}</h3>
                    <div class="col-lg-12 col-md-12">
                        <div class="project-details-image">
                            <img [src]="program.image" width="200px" alt="projects" class="center-image">
                        </div>
                    </div>
                    <div class="features-text">
                        <!-- <h4><i class="flaticon-tick"></i> {{program.bodyAr}}</h4> -->
                        <div *ngIf="program.bodyAr && lang=='ar' && program.bodyAr" class="text-center border shadow m-2 p-3 p-lg-5  "[innerHTML]="program.bodyAr" > </div>
                        <div *ngIf="program.body && lang=='en' && program.body" class="text-center border shadow m-2 p-3 p-lg-5  "[innerHTML]="program.body" > </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->

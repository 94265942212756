<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Details"|translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <!-- <li>Blog Details</li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section dir="auto" *ngIf="Carousel">
  <div  *ngIf="lang=='ar'"  class=" border shadow m-2 p-3 p-lg-5  "[innerHTML]="Carousel.bodyAr" > </div>
  <div *ngIf="lang=='en'" class="border shadow m-2 p-3 p-lg-5  "[innerHTML]="Carousel.body" > </div>
              
</section>
<!-- End Blog Details Area -->

<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"About Us"|translate}}</h2>
                    <ul>
                        <li><a routerLink="/">{{"Home"|translate}}</a></li>
                        <li>{{"About Us"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <!-- <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.png" alt="image">
                </div>
            </div> -->

            <div class="col-md-12">
                <div dir="auto" class="about-content">
                    <span class="sub-title">{{"About Us"|translate}}</span>
                    <div *ngIf="FirstaboutUs">
                    <h2>{{"We Are Future Explorers"|translate}}</h2>
                    <p *ngIf="FirstaboutUs.bodyAR && lang=='ar' && FirstaboutUs.bodyAR">{{FirstaboutUs.bodyAR}}.</p>
                    <p *ngIf="FirstaboutUs.body && lang=='en' && FirstaboutUs.body">{{FirstaboutUs.body}}.</p>
                    <!-- <ul class="features-list">
                        <li><span><i class="fas fa-check"></i> Recommender systems</span></li>
                        <li><span><i class="fas fa-check"></i> Demand prediction</span></li>
                        <li><span><i class="fas fa-check"></i> Omnichannel analytics</span></li>
                        <li><span><i class="fas fa-check"></i> Lead generation</span></li>
                        <li><span><i class="fas fa-check"></i> Dedicated Developers</span></li>
                        <li><span><i class="fas fa-check"></i> 24/7 Support</span></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>


    </div>

    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
</section>
<!-- End About Area -->





<ng-container *ngIf="Carousels">
    <div id="carouselExampleIndicators" class="carousel slide mt-0 " data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" *ngFor="let item of Carousels; let i=index"
                [attr.data-bs-slide-to]="i" [class.active]="i==0" [attr.aria-label]="'Slide ' + (i + 1)">
            </button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let item of Carousels; let i=index" [class.active]="i==0">
                <img [src]="item.image" class="d-block vw-100  vh-100 " alt="carouselImage">
                <div class="carousel-caption d-flex flex-column">
                    <!-- <h1 class="text-white-50" *ngIf="lang=='ar'">{{ item.titleAr.slice(0,50) }}</h1>
                    <h1 class="text-white-50" *ngIf="lang=='en'">{{ item.title.slice(0,50) }}</h1>
                    <p class="p-3 text-white-50" *ngIf="lang=='ar'">{{ item.descriptionAr.slice(0,310) }}...</p>
                    <p class="p-3 text-white-50" *ngIf="lang=='en'">{{ item.description.slice(0,310) }}...</p> -->
                    <div class="banner-btn">
                        <a [routerLink]="['/blog-details', item.id]" class="default-btn rounded-pill me-4">
                            {{ "More" | translate }}<span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</ng-container>


<!-- Start Services Area -->
<section class="services-area ptb-100 bg-F4F7FC">
    <div class="container">
        <div dir="auto" class="section-title">
            <span class="sub-title">{{"Our Services"|translate}}</span>
            <h2>{{"Discover a world of healing at our VR-Psychotherapy Center"|translate}}</h2>
        </div>

        <div dir="auto" class="row" *ngIf="Services && Services.length > 0">
            <ng-container *ngFor="let blog of Services.slice(0,6)">
           
           <div class="col-lg-4 col-md-6 col-sm-6">
                    <div  class="single-blog-post">
                    <div   class="post-image">
                        <a [routerLink]="['/assessment-details', blog.id]"> <img  src="{{ blog.image }}" alt="image"></a>
                    </div>
                    <div class="post-content">
                        <h3 *ngIf="blog.titleAr && lang=='ar' && blog.titleAr" ><a [routerLink]="['/assessment-details', blog.id]">{{ blog.titleAr.slice(0,45) }}</a></h3>
                        <h3 *ngIf="blog.title && lang=='en' && blog.title" ><a [routerLink]="['/assessment-details', blog.id]">{{ blog.title.slice(0,45) }}</a></h3>
                        <p *ngIf="blog.descriptionAr && lang=='ar' && blog.descriptionAr">{{ blog.descriptionAr.slice(0,50) }}...</p>
                        <p  *ngIf="blog.description && lang=='en'&& blog.description">{{ blog.description.slice(0,50) }}...</p>
                        <a  [routerLink]="['/assessment-details', blog.id]" class="read-more-btn">{{"Read More"|translate}} <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div></div>  
        </ng-container >

        </div>
        
    </div>
</section>
<!-- End Services Area -->


<!-- Start Projects Area -->
<section class="projects-area ptb-100">
    <div class="container">
        <div  dir="auto" class="section-title">
            <span class="sub-title">{{"Our programs"|translate}}</span>
            <h2>{{"The VR-Psychotherapy Center offers many diverse activities suitable for different ages"|translate}}</h2>
        </div>
    <div class="row" dir="auto" *ngIf="Programs && Programs.length > 0" >
            <ng-container *ngFor="let blog of Programs.slice(0,6)">
                
                <div  class="col-lg-4 col-sm-6 col-xl-3">
                    <div  dir="auto" class="work-card">
                        <img  src="{{ blog.image }}" alt="image">
    
                        <div dir="auto" class="content">
                            <span>
                                <a *ngIf="blog.titleAr && lang=='ar' && blog.titleAr" >{{ blog.titleAr.slice(0,40)}}</a>
                                <a *ngIf="blog.title && lang=='en' && blog.title" >{{ blog.title.slice(0,40) }}</a>
    
                            </span>
                            <h3>
                                <a  *ngIf="blog.descriptionAr && lang=='ar' && blog.descriptionAr">{{ blog.descriptionAr.slice(0,50) }}...</a>
                                <a *ngIf="blog.description && lang=='en'&& blog.description">{{ blog.description.slice(0,50) }}...</a>
                            </h3>
    
                            <a class="work-btn" [routerLink]="['/program-details', blog.id]">{{"Read More"|translate}}</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
       
    </div>
</section>
<!-- End Projects Area -->

<!-- Start Booking Area -->
<section class="feedback-area ptb-100 bg-color mt-5 border">
    <div class="container ">
        <div class="section-title">
            <span class="sub-title">{{"Book your session now"|translate}}</span>
            <h2>{{"You can book your session through our app"|translate}}</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="single-feedback-item border text-center bg-white  " routerLink="/contact" >
            <p class="text-black fw-bold">{{"Treatment programs"|translate}}</p>
        </div>

        <div class="single-feedback-item border text-center bg-white" routerLink="/contact" >
            <p class="text-black fw-bold">{{"Psychological evaluation"|translate}}</p>
        </div>

        <div class="single-feedback-item border text-center bg-white " routerLink="/contact" >
            <p class="text-black fw-bold">{{"Psychological consultations"|translate}}</p>
        </div>
    </div>
</section>
<!-- End Booking Area -->

<!-- Start Mobile App Area -->
<div class="mobile-app-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mobile-app-image text-center">
                    <img src="assets/img/vr1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mobile-app-content text-center" dir="auto">
                    <h3>{{"Download our application now"|translate}}<br> <span>VR-Psychotherapy</span></h3>
                    <p >{{"Book your session through our mobile app"|translate}} </p>

                    <div class="app-btn-box">
                        <a href="#" class="playstore-btn m-2 rounded-5" target="_blank">
                            <img src="assets/img/cryptocurrency-home/google-play.png" alt="image">
                            Get It On
                            <span>Google Play</span>
                        </a>
                        <a href="#" class="applestore-btn m-2 rounded-5" target="_blank">
                            <img src="assets/img/cryptocurrency-home/app-store.png" alt="image">
                            Get It On
                            <span>Apple Store</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Mobile App Area -->

<!-- ======================================================================== -->

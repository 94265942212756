import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetailsService } from 'src/services/details/details.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent  implements OnInit {
    id : any;
    program:any;
    lang: string;
    constructor(private activatedRoute:ActivatedRoute,public _detailsService:DetailsService,private translateService:TranslateService){}
    ngOnInit(): void {
      this.lang= localStorage.getItem('lang') || 'en'
      this.activatedRoute.paramMap.subscribe((params:ParamMap)=> {
        this.id = params.get('id')!
        console.log("this is paramsdid" +this.id)
        this.getProgramByID()
       });
      }
getProgramByID(){
    this._detailsService.getprogramByID(this.id).subscribe(
        ( response:any) => { console.log(response);
         this.program = response.data
         console.log(this.program)
       }
       );
}
changeLang(lang:string){
  localStorage.removeItem('lang')
  localStorage.setItem('lang',lang)
  this.translateService.use(lang)
  window.location.reload();

}
}

import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/services/home/home.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-projects-style-two',
  templateUrl: './projects-style-two.component.html',
  styleUrls: ['./projects-style-two.component.scss']
})
export class ProjectsStyleTwoComponent  implements OnInit {

    constructor(public _homeService :HomeService,public translateService:TranslateService) { }
Programs :any [];
lang: string;
    ngOnInit(): void {
      this.lang= localStorage.getItem('lang') || 'en'
   this.getPrograms()
    }
    getPrograms(){
      this._homeService.getAllprograms().subscribe(
        (data: any) => {
          this.Programs = data.data;
          console.log(this.Programs);
        },
        (error: any) => {
          console.log('There is an error ', error);
        }
      );
    }
    changeLang(lang:string){
      localStorage.removeItem('lang')
      localStorage.setItem('lang',lang)
      this.translateService.use(lang)
      window.location.reload();
    
    }

}

<!-- <app-navbar></app-navbar> -->

<!-- Start Page Title Area -->
<div dir="auto" class="page-title-area page-title-bg1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{"Events"|translate}}</h2>
                    <ul>
                        <li>{{"Home"|translate}}/</li>
                        <li>{{"Events"|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape-img8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape-img9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape-img10"><img src="assets/img/shape/shape10.png" alt="image"></div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area --><section dir="auto" class="blog-area ptb-100">
    <section dir="auto" class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <ul class="topic-list">
                        <ng-container *ngFor="let event of Events">
                            <li class="single-topic">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="post-image">
                                            <img src="{{ event.image }}" alt="image">
                                        </div>
                                    </div>
                                    <div class="col-lg-9">
                                        <div class="topic-content">
                                            <h3><a *ngIf="event.titleAr && lang=='ar'">{{event.titleAr}}</a></h3>
                                            <h3><a *ngIf="event.title && lang=='en'">{{event.title}}</a></h3>
                                            <div class="post-meta">
                                                <ul>
                                                    <li>{{"From"|translate}}: {{ event.from | date:'shortDate' }}</li>
                                                </ul>
                                                <ul>
                                                    <li>{{"To"|translate}}: {{ event.to | date:'shortDate' }}</li>
                                                </ul>
                                            </div>
                                            <p *ngIf="event.bodyAr && lang=='ar'">{{event.bodyAr}}</p>
                                            <p *ngIf="event.body && lang=='en'">{{event.body}}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <br><br>

                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </section>
<!-- End Blog Area -->

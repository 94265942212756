// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
export const domain='https://arabtesting.org:50116/api/v1/'
// export const domain="https://localhost:7111/api/v1/"

export const programURL=domain+'Program'
export const serviceURL=domain+'Service'
export const carouselURL=domain+'Carousel'
export const aboutUsURL=domain+'AboutUs'
export const activitesURL=domain+'Activity'
export const CategoriesURL=domain+'Category'
export const ContactUSURL=domain+'ContactUs'
export const emailServiceId='service_jrwpwsh';
export const emailTemplateId='template_3czykbt';
export const emailPK='PwwLcVHoprpXGVOKB';

import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/services/home/home.service';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    lang: string;
   Categories:any;
   dropdownOpen = false;


    constructor(
        private router: Router,
        location: Location,
        private _translateService:TranslateService,private _homeService:HomeService,
        
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/index-8' || this.location == '/index-9' || this.location == '/index-11'){
                    this.navbarClass = 'navbar-area navbar-style-two';
                } else {
                    this.navbarClass = 'navbar-area';
                }
            }
        });
    }

    ngOnInit() {
        this.lang= localStorage.getItem('lang') || 'en'
        this.getCategories()
    }
    changeLang(lang:string){
        localStorage.removeItem('lang')
        localStorage.setItem('lang',lang)
        this._translateService.use(lang)
        window.location.reload();

      }
      getCategories(){
        this._homeService.getAllCategories().subscribe(
          (data: any) => {
            this.Categories = data.data;
            console.log(this.Categories);
          },
          (error: any) => {
            console.log('There is an error ', error);
          }
        );
      }
      toggleDropdown(event: Event) {
        event.stopPropagation(); // Prevents the document click event from closing the dropdown
        this.dropdownOpen = !this.dropdownOpen;
    }
    // @HostListener('document:click', ['$event'])
    // closeDropdown(event: Event) {
    //     if (!this.dropdownToggle.nativeElement.contains(event.target) && !this.dropdownMenu.nativeElement.contains(event.target)) {
    //         this.dropdownOpen = false;
    //     }
    // }
}
